import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./meetUs.css";
import hhSalon from "../../media/images/ruPlace.jpg";
import emppa from "../../media/images/Emilia.jpeg";
import Helmet from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import julia from "../../media/images/Julia_profile_pic.jpeg";
import hanna from "../../media/images/hanna.jpg";
import { useEffect } from "react";
import ga from "../../googleAnalytics";

export const MeetUs = ({ language }) => {
  const redirectToHannaTimma = () => {
    window.location.href = "https://varaa.timma.fi/tmihannaheikkila";
  };
  useEffect(() => {
    ga.trackPageView();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Meet us</title>
        <meta
          name="description"
          property="og:description"
          content="Haluatko tietää meistä lisää? Tapaa meidät täällä"
        />
        <meta property="og:title" content="Meet Us" />
        <meta property="og:url" content="www.helmihelsinki.fi/meetUs" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, meet us, about, parturikampaamo, kampaaja, meistä, om oss, frisör, Helmi Helsinki "
        />
      </Helmet>
      <Container className="container-page meetUs-cont">
        <Row>
          <Col>
            <h1 style={{ textAlign: "center", marginBottom: "1vh" }}>
              {language === "fin" && "Meistä"}
              {language === "eng" && "About us"}
              {language === "swe" && "Om oss"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={5} id="meetus-pic-col">
            <Image alt="salon pic" src={hhSalon} className="meetUs-pic"></Image>
          </Col>
          <Col md={7} style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}>
            <Row className="meetUs-description-flex">
              <Col>
                {language === "fin" && (
                  <div>
                    Ota hetken irtiotto arjesta ja tule Helmi Helsinkiin. Meiltä
                    löydät laajan valikoiman parturi-, kampaamo-, kampaus- 
             		sekä meikkipalveluita. Toteutamme tyyliisi sopivan
                    kokonaisuuden. Palvelemme ammattitaidolla ja asiakkaan
                    toiveita huomioon ottaen. Kouluttaudumme myös jatkuvasti
                    pysyäksemme mukana uusimmissa trendeissä. Suurin osa
                    hiustuotteistamme ovat vegaanisia ja mahdollisimman
                    luonnonmukaisia. Myynnissä meiltä löytyy Davines, Björk  
                    sekä Eleven Australian hiustuotteita.{" "}
                  </div>
                )}
                {language === "eng" && (
                  <div>
                    Take time for yourself and come to Helmi Helsinki. We offer
                    a wide range of barber-, hairdressing-, updo- and makeup services.
                    We will create a look that fits your style.
                    We offer professional services and always take the customers
                    wishes into consideration. We are also educating ourselves
                    to stay on board with the newest trends. Most of our
                    hairproducts are vegan and as organic as possible. We sell
                    Davines, Björk and Eleven Australia hair products.
                    
                  </div>
                )}
                {language === "swe" && (
                  <div>
                    Ta tid för dig själv och kom till Helmi Helsinki. Hos oss
                    hittar du ett brett urval av barberar-, frisör-,
                    håruppsättnings- och sminktjänster. Vi gör en helhet
                    som passar just dig. Vi håller oss även uppdaterade inom det
                    nyaste inom hårtrender. De flesta av våra produkter är
                    veganska och så organiska som möjligt. Vi säljer Davines,
                    Björk och Eleven Australia hårprodukter.
                   
                  </div>
                )}
              </Col>
              <Col md={12} style={{ fontSize: "1.2rem", liheHeight: "1.5rem" }}>
                <br />
                {language === "fin" && (
                  <div>
                    Avasimme ovemme vuonna 2019, mutta olemme kaikki olleet
                    alalla jo pidemmän aikaa. Aiemmin olimme useita vuosia
                    Yliopistonkadulla, jonka jälkeen olimme Vuorikadulla
                    muutaman vuoden. Sitten päätimme perustaa oman paikan; Helmi
                    Helsinki. Olemme viihtyisä salonki, jossa ammattitaitoiset
                    ammattilaiset palvelevat sinua yksilöllisesti. Helmi
                    Helsinki sijaitsee rauhallisella sisäpihalla Helsingin
                    ydinkeskustassa, Mikonkatu 13. Tervetuloa!
                  </div>
                )}
                {language === "eng" && (
                  <div>
                    We opened our doors in 2019, but we have been a long time in
                    the hairdressing industry. Earlier we worked several years
                    on Yliopistonkatu, then a few years on Vuorikatu. Then
                    finally we decided to open our own place Helmi Helsinki. We
                    are a cozy salon, with experienced professionals that will
                    treat you individually. Helmi Helsinki is located at a
                    courtyard in the centre of Helsinki, Mikonkatu 13. Welcome!{" "}
                  </div>
                )}
                {language === "swe" && (
                  <div>
                    Vi öppnade våra dörrar år 2019, men vi har varit inom
                    branschen en längre tid. Tidigare var vi ett flertal år på
                    Universitetsgatan, några år på Bergsgatan och sedan öppnade
                    vi äntligen vårt eget ställe; Helmi Helsinki. Vi är en
                    trivsam salong där professionella stylister tar väl hand om
                    dig. Helmi Helsinki befinner sig på en trivsam innergård i
                    mitten av Helsingfors centrum, Mikaelsgatan 13. Välkommen!{" "}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="meetUs-row"></Row>
      </Container>

      <Container fluid>
        <Row className="meetUs-row meetUs-worker-row">
          <div className="meetUs-worker-row-overlay"></div>
          <h1 className="meetUs-titles">
            {language === "fin" && "Sinua Palvelevat"}
            {language === "eng" && "At your service"}
            {language === "swe" && "Vi betjänar dig"}
          </h1>
          <Col
            xs={12}
            lg={{ offset: 0, span: 2 }}
            className="meetUs-worker-col"
          >
            <Row className="flex-center-column">
              <Col>
                <Image alt="Emilia" src={emppa} className="workers-pic"></Image>
              </Col>
              <Col className="worker-name">Emilia</Col>
              <Col xs={4}>
                <div
                  style={{ borderBottom: "0.5px solid black", height: "2px" }}
                ></div>
              </Col>
              <Col className="worker-xp">
                {language === "fin" && (
                  <div>
                    Stylisti <br /> Meikkitaiteilija
                  </div>
                )}
                {language === "eng" && (
                  <div>
                    Stylist <br /> Makeup artist
                  </div>
                )}
                {language === "swe" && (
                  <div>
                    Stylist <br /> Makeup artist
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={9} lg={{ offset: 1, span: 2 }} className="meetUs-worker-col">
            <Row className="flex-center-column">
              <Col>
                <Image alt="Julia" src={julia} className="workers-pic"></Image>
              </Col>
              <Col className="worker-name">Julia</Col>
              <Col xs={4}>
                <div
                  style={{ borderBottom: "0.5px solid black", height: "2px" }}
                ></div>
              </Col>
              <Col className="worker-xp">
                {language === "fin" && <div>Stylisti</div>}
                {language === "eng" && <div>Stylist</div>}
                {language === "swe" && <div>Stylist</div>}
              </Col>
            </Row>
          </Col>
          <Col xs={9} lg={{ offset: 1, span: 2 }} className="meetUs-worker-col">
            <Row className="flex-center-column">
              <Col>
                <Image alt="Hanna" src={hanna} className="workers-pic"></Image>
              </Col>
              <Col className="worker-name">Hanna</Col>
              <Col xs={4}>
                <div
                  style={{ borderBottom: "0.5px solid black", height: "2px" }}
                ></div>
              </Col>
              <Col className="worker-xp">
                {language === "fin" && <div>Stylisti</div>}
                {language === "eng" && <div>Stylist</div>}
                {language === "swe" && <div>Stylist</div>}
              </Col>
              <Col className="flex-center-column">
                <div className="my-2 worker-xp book-hanna">
                  {language === "fin" && <p>Tästä voit varata ajan Hannalle</p>}
                  {language === "eng" && (
                    <p>Here you can book your time with Hanna</p>
                  )}
                  {language === "swe" && (
                    <p>Här kan du boka din tid hos Hanna</p>
                  )}
                </div>
                <Button
                  variant="outline-secondary"
                  onClick={() => redirectToHannaTimma()}
                >
                  {language === "fin" && "Varaa tästä"}
                  {language === "eng" && "Book here"}
                  {language === "swe" && "Boka här"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

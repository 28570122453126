import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { useState } from "react";
import './footer.css'
import logo from '../../media/images/Helmihelsinki.png'
import { useNavigate, Link } from "react-router-dom"
import davines from '../../media/images/logos/davines.png';
import fourReasons from '../../media/images/logos/fourReasons.png';
import bjork from '../../media/images/logos/bjork.png';
import brasilian from '../../media/images/logos/brazilianBound.png';
import { CookiesPolicy } from "../cookiesPolicy";
import ga from '../../googleAnalytics';

export const Footer = ({language}) => {
    const [modalShow, setModalShow] = useState(false);
    const navigate=useNavigate();
    const handleNavigate = (location) => {
        ga.trackGAEvent('footer_button_click', 'footer_buttons', location);
        navigate(location)
    }
    return(
        <Container className="footer-container" fluid>
            <Row>
                <Col className="flex-center-column footer-list-title">
                    {language === "fin" && "Lisää meistä"}
                    {language === "eng" && "More about us"}
                    {language === "swe" && "Mera om oss"}
                </Col>
            </Row>
            <Row>
                <Col className="flex-center-column footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "/")}>
                        {language==='fin' && 'Etusivu'}
                        {language==='eng' && 'Home'}
                        {language==='swe' && 'Startsida'}
                    </Link></Col>
                <Col className=" footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/meetUs" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "/meetUs")}>
                        {language==='fin' && 'Meistä'}
                        {language==='eng' && 'Meet us'}
                        {language==='swe' && 'Möt oss'}
                    </Link></Col>
            </Row>
            <Row>
                <Col className="flex-center-column footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/booking" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "/booking")}>
                        {language==='fin' && "Varaa aika"}
                        {language==='eng' && "Book time"}
                        {language==='swe' && "Boka tid"}
                    </Link></Col>
                <Col className=" footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/prices">
                        {language === 'fin' && "Hinnasto"}
                        {language === 'eng' && "Prices"}
                        {language === 'swe' && "Prislista"}
                    </Link> </Col>
            </Row>
            <Row>
                <Col className="flex-center-column footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/contacts" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "/contacts")}>
                        {language==='fin' && " Yhteystiedot"}
                        {language==='eng' && " Contacts"}
                        {language==='swe' && " Kontaktuppgifter"}
                    </Link></Col>
                <Col className=" footer-list" xs={{span:4, offset:2}}>
                    <Link className="LinkComponent" to="/services" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "/services")}>
                        {language==='fin' && "Palvelut"}
                        {language==='eng' && "Services"}
                        {language==='swe' && "Tjänster"}
                    </Link></Col>
            </Row>
            <Row>
                <Col className="flex-center-column" xs={{span:4, offset:4}}><Image id="footer-logo" alt="Helmi Helsinki logo" src={logo}></Image></Col>
            </Row>
            <Row>
                <Col xs={12} md={{span:4, offset:3}}>
                    <Row className="flex-center-column">
                        <Col>
                            {language==='fin' && "Mikonkatu 13, 00100"}
                            {language==='eng' && "Mikonkatu 13, 00100"}
                            {language==='swe' && "Mikaelsgatan 13, 00100"}
                        </Col>
                        <Col>
                            {language==='fin' && "Helsinki, Suomi"}
                            {language==='eng' && "Helsinki, Suomi"}
                            {language==='swe' && "Helsingfors, Finland"}
                        </Col>
                        <Col>
                            {language==='fin' && "Sähköposti: helmihelsinki@hotmail.com"}
                            {language==='eng' && "Email: helmihelsinki@hotmail.com"}
                            {language==='swe' && "E-post: helmihelsinki@hotmail.com"}
							<br></br>
							{language === "eng" && "(Appointments only via timma or by phone!)"}
							{language === "fin" && "(Ajanvaraus vain timman kautta tai soittamalla!)"}
							{language === "swe" && "(Tidsbokning endast via timma eller per telefon!)"}
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={4}>
                    <Row className="flex-center-column">
                        <Col>
                            {language==='fin' && <div>Puhelinnumero: <br/>+358503413400</div>}
                            {language==='eng' && <div>Phone Number: <br/>+358503413400</div>}
                            {language==='swe' && <div>Telefonnummer: <br/>+358503413400</div>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="flex-center-column">
                    <Button variant="dark" id="footer-btn" onClick={()=>handleNavigate('/booking')}>
                        {language === "fin" && "Varaa aikasi!"}
                        {language === "eng" && "Book your time!"}
                        {language === "swe" && "Boka din tid!"}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Row>
                    <Col style={{textAlign:"center"}}>
                        {language==='fin' && "Seuraa meitä:"}
                        {language==='eng' && "Follow us:"}
                        {language==='swe' && "Följ oss:"}
                    </Col>
                </Row>
                <Row>
                    <Col className="flex-center-row" xs={{span:4, offset:4}}>
                        <a href="https://www.facebook.com/HelmiHelsinki13/" target="blank"><img className="footer-sm-logo" src="https://img.icons8.com/color/344/facebook.png" alt="Facebook icon" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "facebook")}></img></a>
                        <a href="https://www.instagram.com/helmihelsinki/" target="blank"><img className="footer-sm-logo" src="https://img.icons8.com/color/344/instagram-new--v1.png" alt="Instagram icon" onClick={()=>ga.trackGAEvent('footer_button_click', 'footer_buttons', "instagram")}></img></a>
                    </Col>
                </Row>
            </Row>
            <Row style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                        {language === 'fin' && "Käytetyt merkit:"}
                        {language=== 'eng' && "Used brands:"}
                        {language=== 'swe' && "Använda brand:"}
                    </Col>
                </Row>
                <Row className="brandsLogos">
                    <Col xs={3} md={1}><Image alt="Davines-logo" className="footer-brands-logo" src={davines}></Image></Col>
                    <Col xs={3} md={1}><Image alt="Four Reasons Logo" className="footer-brands-logo" src={fourReasons}></Image></Col>
                    <Col xs={3} md={1}><Image alt="Bjork logo" className="footer-brands-logo" src={bjork}></Image></Col>
                    <Col xs={3} md={1}><Image alt="Brasilian bound logo" className="footer-brands-logo" src={brasilian}></Image></Col>
                </Row>
            </Row>
            <Row>
                <Col style={{textAlign: 'center', paddingTop: '8vh'}}>© 2022 Helmi Helsinki</Col>
            </Row>
            <Row>
                <Col id="cookiesPolicy-btn" onClick={() => setModalShow(true)}>Cookies Policy</Col>
            </Row>
            <CookiesPolicy
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Container>
    )
}
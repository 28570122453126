import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./servicesBannerList.css";
import { listOfServices } from "../servicePage/listOfServices";
import ga from "../../googleAnalytics";

export const ServicesBannerList = ({ language }) => {
  const navigate = useNavigate();
  const handleNavigate = (location) => {
    ga.trackGAEvent(
      "frontpage_button_click",
      "frontpage_servicesBanner_buttons",
      location
    );
    navigate(location);
  };
  return (
    <Container className="container-page">
      <Row>
        <Col
          xs={12}
          lg={6}
          className="service-banner-left-box"
          data-aos="fade-down"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-mirror="false"
          data-aos-once="true"
        >
          <Row>
            <Col className="serviceBanner-text" id="serviceBanner-HH">
              Helmi Helsinki
            </Col>
          </Row>
          <Row>
            <Col className="serviceBanner-text" id="servicesBanner-title">
              {language === "fin" && "Palvelumme"}
              {language === "eng" && "Our services"}
              {language === "swe" && "Våra tjänster"}
            </Col>
          </Row>
          <Row>
            <Col className="serviceBanner-text" id="servicesBanner-description">
              {language === "fin" && (
                <div>
                  Helmi Helsingistä löydät laajan valikoiman palveluja niin
                  naisille kuin miehille. Meiltä löydät parturi-, kampaamo-,
                  kampaus- sekä meikkipalveluja. Alta löydät kaikki
                  palvelumme.
                </div>
              )}
              {language === "eng" && (
                <div>
                  In Helmi Helsinki you can find a diversity of services for
                  both men and women. We offer a wide range of barber-,
                  hairdressing-, updo-, and makeup services. You can check
                  all our services below.
                </div>
              )}
              {language === "swe" && (
                <div>
                  Hos oss på Helmi Helsinki hittar du ett brett urval av
                  barberar-, frisör-, håruppsättnings- och sminktjänster.
                  Du kan hitta alla våra tjänster nedan.
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="serviceBanner-text">
              <Button
                id="servicesBanner-btn"
                variant="outline-dark"
                onClick={() => handleNavigate("/services")}
              >
                {language === "fin" && "Katso palvelumme"}
                {language === "eng" && "Check our services!"}
                {language === "swe" && "Kolla våra tjänster!"}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          className="hiddePhone"
          data-aos="fade-left"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-mirror="false"
          data-aos-once="true"
        >
          <Row className="banner-img-row">
            <Col
              xs={12}
              md={5}
              className="serviceBanner-col-img sb-col-1"
              onClick={() => handleNavigate("/services/leikkaukset")}
            >
              {language === "fin" && listOfServices[0].title.fin}
              {language === "eng" && listOfServices[0].title.eng}
              {language === "swe" && listOfServices[0].title.swe}
            </Col>
            <Col
              xs={12}
              md={5}
              className="serviceBanner-col-img sb-col-2"
              onClick={() => navigate("/services/varjaykset")}
            >
              {language === "fin" && listOfServices[1].title.fin}
              {language === "eng" && listOfServices[1].title.eng}
              {language === "swe" && listOfServices[1].title.swe}
            </Col>
          </Row>
          <Row className="banner-img-row">
            <Col
              xs={12}
              md={5}
              className="serviceBanner-col-img sb-col-3"
              onClick={() => handleNavigate("/services/kampauksetjameikit")}
            >
              {language === "fin" && listOfServices[2].title.fin}
              {language === "eng" && listOfServices[2].title.eng}
              {language === "swe" && listOfServices[2].title.swe}
            </Col>
            <Col
              xs={12}
              md={5}
              className="serviceBanner-col-img sb-col-5"
              onClick={() => handleNavigate("/services/ripsetjakulmat")}
            >
              {language === "fin" && listOfServices[3].title.fin}
              {language === "eng" && listOfServices[3].title.eng}
              {language === "swe" && listOfServices[3].title.swe}
            </Col>
          </Row>
          <Row className="banner-img-row">
            <Col
              xs={12}
              md={5}
              className="serviceBanner-col-img sb-col-6"
              onClick={() => handleNavigate("/services/hoidot")}
            >
              {language === "fin" && listOfServices[4].title.fin}
              {language === "eng" && listOfServices[4].title.eng}
              {language === "swe" && listOfServices[4].title.swe}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

import { Col, Container, Row } from "react-bootstrap";
import "./pricesComponent.css";
import { leikkaukset } from "./listOfPrices";
import { värjäykset } from "./listOfPrices";
import { kampauksetJaMeikit } from "./listOfPrices";
import { ripsetJaKulmat } from "./listOfPrices";
import { hoidot } from "./listOfPrices";
import Helmet from "react-helmet";
import homepagePic from "../../media/images/homepagePic.png";
import { listOfServices } from "../servicePage/listOfServices";
import ga from "../../googleAnalytics";
import { useEffect } from "react";

export const PricesListComponent = ({ language }) => {
  useEffect(() => {
    ga.trackPageView();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Helmi Helsinki | Hinnasto</title>
        <meta
          name="description"
          property="og:description"
          content="Täältä löydät Helmi Helsingin palvelut ja hinnaston"
        />
        <meta property="og:title" content="Hinnasto" />
        <meta property="og:url" content="www.helmihelsinki.fi/prices" />
        <meta property="og:image" content={homepagePic} />
        <meta
          name="keywords"
          content="hairdresser, parturi, Make up, salon, Helsinki, Mikonkatu, kampaamo, website, hinnasto, prices, hinnat, pris, priser, frisörpriser, kampaamohinnat, parturihinnat, kynnet, kynsien hinnat, barberarpriser"
        />
      </Helmet>
      <Container className="container-page">
        <Title language={language} />
        <Row>
          <Col xs={12} lg={6}>
            <PriceService language={language} service={leikkaukset} index={0} />
            <PriceService language={language} service={värjäykset} index={1} />
          </Col>
          <Col xs={12} lg={6}>
            <PriceService
              language={language}
              service={kampauksetJaMeikit}
              index={2}
            />
            <PriceService
              language={language}
              service={ripsetJaKulmat}
              index={3}
            />
            <PriceService language={language} service={hoidot} index={4} />
            <Info language={language} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Title = ({ language }) => {
  return (
    <>
      <Row>
        <Col
          style={{
            textAlign: "center",
            marginTop: "4vh",
            letterSpacing: "1vh",
          }}
        >
          <h1>
            {language === "fin" && "Hinnasto"}
            {language === "eng" && "Pricelist"}
            {language === "swe" && "Prislista"}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            textAlign: "center",
            marginBottom: "10vh",
            letterSpacing: "0.5vh",
          }}
        >
          {language === "fin" && "Täältä löydät kaikkien palvelumme hinnat"}
          {language === "eng" && "Find the prices for all our services here"}
          {language === "swe" && "Här hittar du alla våra tjänsters priser"}
        </Col>
      </Row>
    </>
  );
};

const PriceService = ({ language, service, index }) => {
  return (
    <Row className="prices-groups">
      <Col className="service-price-title">
        <h3>
          {language === "fin" && listOfServices[index].title.fin}
          {language === "eng" && listOfServices[index].title.eng}
          {language === "swe" && listOfServices[index].title.swe}
        </h3>
      </Col>
      {service.map((price) => (
        <Row className="service-price-description" key={price.id}>
          <Col xs={8}>
            {language === "fin" && price.name.fin}
            {language === "eng" && price.name.eng}
            {language === "swe" && price.name.swe}
          </Col>
          <Col xs={4}>{price.price}</Col>
        </Row>
      ))}
    </Row>
  );
};

const Info = ({ language }) => {
  return (
    <Row>
      <Col className="service-price-title">
        {language === "fin" && (
          <div>
            Hinnat perustuvat työhön varattuun aikaan, käytettyihin tuotteisiin
            ja niiden määrään sekä työn ammatilliseen vaativuuteen. Hinnastossa
            mainittu työn suorittamiseksi kuluva aika saattaa vaihdella hiusten
            paksuuden ja pituuden mukaan.
          </div>
        )}
        {language === "eng" && (
          <div>
            The prices are based on the time, the products used and their
            quantity, and the professional complexity of the work. The time
            required to complete the work mentioned in the pricelist may vary
            depending on the thickness and length of the hair.
          </div>
        )}
        {language === "swe" && (
          <div>
            Priserna baserar sig på tiden, produkter som använts och deras mängd
            samt på hur tekniskt krävande arbetet är. Den tid som krävs för att
            slutföra arbetet som nämns i prislistan kan variera beroende på
            hårets tjocklek och längd.
          </div>
        )}
      </Col>
    </Row>
  );
};
